@import '@scss/variables';

.header-tv {
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: 1;

  &__container {
    border-bottom: 1px solid #292e33;
  }

  &__logo {
    display: flex;
    align-items: center;
  }

  &__login {
    &-link {
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__nav {
    &-link {
      text-decoration: none;
      color: #ababad;

      &.active {
        color: #fcc428;
      }
    }
  }
}
