.links {
  &__title {
    font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 40px;
    line-height: 48px;
    font-weight: 500;
    margin-bottom: 40px;
  }
}

.container--links {
  max-width: 1140px;
  margin: 0 auto;
}

.paginationItemSelected {
  background-color: #007aff !important;
  color: white !important;
}
