@import '@scss/variables';

.profile {
  &-menu {
    transform: translateX(20px);
    margin-top: 10px;
    background-color: #fff;
    width: 290px;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(123, 135, 158, 0.12),
      0 8px 32px rgba(123, 135, 158, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 8px;

    &__item {
      min-height: 44px !important;

      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $color-base;
        text-decoration: none;
      }
    }

    &__ico {
      display: inline-block;
      width: 60px;
    }

    &-close {
      position: absolute;
      top: 18px;
      right: 14px;
      width: 24px;
      display: block;
      cursor: pointer;

      svg {
        display: block;
      }
    }

    &-arrow {
      position: absolute;
      right: 0;
      transform: translateX(-50%);
      top: 0;
      margin-top: 0;
      width: 24px;
      height: 12px;
      z-index: 2;

      &:before {
        content: '';
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 24px 24px;
        border-color: transparent transparent #fff;
      }
    }
  }

  &-popper {
    z-index: 100;
  }
}
