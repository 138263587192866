@import '@scss/variables';
@import '@scss/mixins/media-query';

.news-item {
  $root: &;

  position: relative;
  transition: $transition-base;
  overflow: hidden;
  padding: 16px;
  padding-bottom: 32px;
  border-radius: 10px;

  &:hover {
    #{$root}__text {
      color: $color-primary;
    }
    box-shadow: 0px 10px 30px rgba(206, 214, 224, 0.5),
      0px 1px 1px rgba(206, 214, 224, 0.4);
  }

  &__image {
    display: block;
    width: 100%;
    height: 222px;
    border-radius: 8px;
    margin-bottom: 16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-bg-grey;
  }

  &__text {
    margin-bottom: 8px;
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: inherit;
    text-decoration: none;
  }

  &__region {
    display: inline-block;
    transition: $transition-base;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 2px 8px;
    color: $color-primary-text;
    border: 1px solid #dee8fa;
    border-radius: 3px;
    width: fit-content;

    &:hover {
      color: $color-primary;
      border-color: $color-primary;
    }
  }
}
