@import '@scss/variables';

.grade-widget {
  position: relative;
  margin-bottom: 32px;
  padding: 32px 72px 44px;
  background-color: #fff;
  border-radius: 12px;
  &__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  &__item {
    text-decoration: none;
    background-color: #f7f7f7;
    height: 184px;
    width: 120px;
    padding: 16px 8px;
    border-radius: 24px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    &-title {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      height: 40px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      overflow-wrap: anywhere;
    }
    &-value {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-color: #38b048;
      color: #fff;
      font-size: 30px;
      line-height: 56px;
      text-align: center;
      &--bad {
        background-color: #fa7d64;
      }
    }
    &-date {
      color: #626b7a;
      font-size: 14px;
      opacity: 0;
      transition: all 0.3s;
    }
    &:hover {
      background-color: #fff;
      box-shadow: 0 8px 24px rgba(202, 211, 224, 0.25), 0 1px 2px #dce2eb;
      .grade-widget__item-date {
        opacity: 1;
      }
    }
  }
  &__slider {
    position: relative;

    .slider {
      position: relative;
      margin: {
        left: -56px;
        right: -56px;
      }

      &__scroller {
        margin: 0 52px;
      }

      &__item {
        margin-right: 8px;
        & + & {
          margin-right: 8px;
        }
      }

      &__btn {
        position: absolute;
        background-color: #f2f4f7;
        border-radius: 6px;
        width: 44px;
        height: 44px;
        z-index: 10;
        cursor: pointer;
        top: 70px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../../../assets/icons/slider-arrow-right.svg');
        color: transparent;
        border: none;
        visibility: hidden;
        opacity: 0;

        &.visible {
          visibility: visible;
          opacity: 1;
        }

        &--left {
          transform: rotateY(180deg);
        }

        &--right {
          right: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .video-widget__arrow--prev {
    left: -10px;
  }
  .video-widget__arrow--next {
    right: -10px;
  }

  .grade-widget {
    margin-bottom: 40px;
    padding: 24px 32px 30px;
    background-color: #fff;
    border-radius: 12px;
  }
}
