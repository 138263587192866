.swiper-slider-news {
  .swiper-slide-thumb-active {
    .swiper-slider-news__thumb-image {
      border: 2px solid #007aff;
      border-radius: 2px;
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  &__thumb-image-container {
    margin-top: 8px;
    width: 100px;
    height: 62px;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__thumb-image {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;
    border: 2px solid transparent;
  }

  &__image-container {
    width: $news-detail-page-width;
    height: 600px;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__arrow {
    transition: $transition-base;
    cursor: pointer;
    position: absolute;
    width: 44px;
    height: 44px;
    background-color: #f2f4f7;
    border-radius: 6px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    background-position: center;
    background-repeat: no-repeat;

    &--left {
      left: 16px;
    }

    &--right {
      right: 16px;
    }

    &--disable {
      opacity: 0;
    }
  }
}
