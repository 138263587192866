.collection-widget {
  position: relative;
  margin-top: 80px;
  &__slider {
    position: relative;
  }
  &__arrow {
    position: absolute;
    background-color: #f2f4f7;
    border-radius: 6px;
    width: 44px;
    height: 44px;
    z-index: 10;
    cursor: pointer;
    top: 120px;
    background-position: center;
    background-repeat: no-repeat;
    &--next {
      right: -20px;
    }
    &--prev {
      left: -20px;
      transform: rotate(180deg);
    }
    &--disable {
      display: none;
    }
  }
}
