@import '@scss/mixins/media-query';

.fixed {
  overflow: hidden;
}
.menu-mobile {
  display: block;
  z-index: 11;
  position: fixed;
  top: 76px;
  left: 0;
  height: calc(100vh - 76px);
  width: 100%;
  max-width: calc(768px / 2);
  overflow: auto;
  background-color: white;
  border: {
    top: 1px solid rgba(0, 0, 0, 0.04);
    right: 1px solid rgba(0, 0, 0, 0.04);
  }

  &__item {
    padding: 14px 16px;
  }

  &__link {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    color: #0c1524;
    text-transform: none;
    text-decoration: none;
    transition: all 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &.active {
      color: #0278ff;
    }

    @include media-query('phone') {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
