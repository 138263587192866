.news-page {
  &__img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    object-fit: contain;
    max-height: 720px;
    border-radius: 8px;

    @include media-query('phone') {
      max-height: 220px;
    }
  }

  &__body-wrapper {
    max-width: 870px;

    & > * {
      display: block !important;
      margin-bottom: 32px !important;
    }

    p {
      font-size: 18px;
      line-height: 1.5;

      @include media-query('tablet') {
        font-size: 16px;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__line {
    clear: both;
    border: 1px solid #cad3e0;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  &__exta-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__share {
    display: flex;
    flex-direction: row;

    @include media-query('tablet') {
      flex-direction: column;
    }
  }

  &__sources-item {
    @extend %font-caption;
    margin: 0;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__sources-item-title {
    color: $color-minor-text;
  }

  &__sources-item-name {
    color: $color-text;
  }

  &__file-item {
    display: block;
  }
}
