.news {
  margin-top: 48px;

  &__toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 34px;

    & > *:not(:last-child) {
      margin-right: 17px;
    }

    .news__toolbar-tabs {
      .react-tabs__tab:focus {
        box-shadow: none;
        border-color: transparent;
        outline: none;
      }
    }
  }

  &__header-icon.MuiButton-root {
    color: $color-text;
    transition: all 0.2s ease;
    opacity: 0.5;

    &:hover {
      color: $color-primary;
      background-color: transparent;
      opacity: 1;
    }

    &.news__header-icon--active {
      opacity: 1;
    }
  }

  &__search {
    flex-grow: 1;
  }

  &__content {
    width: 100%;

    &.news__content--list {
      display: block;

      .news-item {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        width: 950px;
        height: auto;
        position: relative;
        margin-bottom: 0;
        padding-bottom: 40px;

        &:after {
          content: '';
          position: absolute;
          height: 1px;
          bottom: 0;
          left: 16px;
          right: 0;
          background-color: #e5e5e5;
        }

        //border-bottom: 1px solid #e5e5e5;

        &__object {
          order: 2;
        }

        &__image {
          width: 100px;
          height: 100px;
          margin-bottom: 0;
        }

        &__text {
          width: 750px;
          margin-top: 16px;
          margin-bottom: 8px;
          @extend %font-h4;
        }
      }
    }
  }

  &__filters {
    &--closed {
      display: none;
    }

    margin-top: -27px;
    margin-bottom: 30px;
  }

  &__helpers {
    @extend %font-caption;
    margin-top: -14px;
    margin-bottom: 32px;
  }

  &__helpers-news-count {
    display: inline-block;
    margin-right: 25px;
  }

  &__helpers-filters-count {
    display: inline-block;
    text-align: center;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fa5332;
    margin-right: 5px;
  }

  &__helpers-filters-reset {
    cursor: pointer;
  }
}

.news-filter-block {
  &__info {
    color: #75747b;
    font-size: 14px;
    font-weight: 300;
    .MuiGrid-root {
      padding: 0 !important;
      margin: 0 !important;
      margin-top: -10px !important;
      margin-bottom: 10px !important;
    }
  }
  &__filter-reset {
    margin-left: 20px;
  }
  &__filter-count {
    background: #fa5332;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
  }
  &__filter-reset {
    cursor: pointer;
  }
  &__filter-count {
    display: inline-block;
  }
}
