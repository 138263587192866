@import '@scss/variables';

.service-item {
  height: calc(100% - 32px);
  margin-bottom: 32px;
  padding: 24px;
  border-radius: 16px;
  transition: all 0.35s;
  cursor: pointer;
  color: $color-text;
  display: block;
  text-decoration: none;
  background: #f5f9ff;

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 12px;
    transition: color 0.35s;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
  }
  &__ico {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 80px;
    height: 80px;
  }
  &:hover {
    box-shadow: 0 8px 24px rgba(202, 211, 224, 0.25), 0 1px 2px #dce2eb;
    background-color: #fff;
    .service-item__title {
      color: $color-primary;
    }
  }
}

@media (max-width: 768px) {
  .service-item {
    padding: 20px 0;
    &__ico {
      width: 100px;
      height: 100px;
      background-size: 60%;
    }
  }
}
