@import '@scss/variables';
@import '@scss/mixins/media-query';

.profile-block {
  background: #f5f9ff;
  border-radius: 12px;
  margin-top: 32px;
  padding: 48px;

  &__description {
    max-width: 500px;
    color: #636975;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
  }

  &__daybook,
  &__my-files {
    background: #fff;
    padding: 48px;
    border-radius: 12px;
  }

  &__my-files {
    margin-top: 32px;
    padding-top: 24px;
  }

  &__child-list {
    background-color: #ffffff;
    padding-left: 0;
    margin: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: center;

    @include media-query('tablet') {
      flex-direction: column;
      gap: 5px;
    }
    & li {
      border-radius: 16px;
      padding: 16px 24px;

      &:hover {
        background-color: #f2f4f7;
      }

      &.react-tabs__tab--selected {
        background-color: #f5f9ff;
        box-shadow: none;

        & .student {
          &__avatar {
            background-color: #075ff7;
            color: #ffffff;
          }
          &__name {
            color: inherit;
          }
        }
      }
    }
  }
}

.student {
  &__name {
    color: #075ff7;
    transition: $transition-base;
  }
}

.teacher {
  display: inline-block;
  text-align: left;
  &-wrap {
    margin: 40px 0;
    text-align: center;
  }
  &__img {
    background-color: #f2f4f7;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 24px;
    position: relative;
    &:before {
      width: 20px;
      height: 20px;
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute;
      background-color: #38b048;
      border: 3px solid #ffffff;
      right: 0;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  &__text {
    margin-bottom: 16px;
  }
}

.first-block {
  background: #fff;
  border-radius: 16px;
  margin: 64px 0;
  &__img {
    max-width: 435px;
    img {
      max-width: 100%;
    }
  }
  &__text {
    margin-bottom: 32px;
    max-width: 450px;
  }
}

@media (max-width: 768px) {
  .profile-block {
    margin-top: 64px;
    background: #f5f9ff;
    border-radius: 12px;
    padding: 24px;
  }
}

.files-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
}

.file {
  width: 176px;
  height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #cad3e0;
  padding: 4px;
  grid-column-end: span 2;
  margin: 12px;

  &__header {
    flex: none;
    height: 32px;
  }

  &__body {
    position: relative;
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f4f7;
    padding: 2px;
    max-height: 108px;

    img {
      flex: none;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__footer {
    flex: none;
    height: 52px;
    display: flex;
    align-items: center;

    &-file-icon {
      flex: none;
      padding-left: 4px;

      svg {
        display: block;
      }
    }

    &-label {
      flex: auto;
      font-size: 14px;
      line-height: 18px;
      padding: 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 36px;
    }

    &-down-icon {
      flex: none;
      padding-right: 8px;

      svg {
        width: 10px;
      }
    }
  }
}
