@import '@scss/variables';

.homework-widget {
  position: relative;
  margin-bottom: 32px;
  padding: 32px 72px 60px;
  background-color: #fff;
  border-radius: 12px;

  &__label {
    font-size: 18px;
    margin-bottom: 28px;
    text-transform: capitalize;
  }
  a {
    color: $color-primary;
  }
}

@media (max-width: 768px) {
  .homework-widget {
    margin-bottom: 40px;
    padding: 24px 32px 30px;
    background-color: #fff;
    border-radius: 12px;
  }
}
