$prim-lighter: rgba(187, 222, 253, 0.25);
$result-border: #bbdefd;

.react-select {
  &__control {
    & .react-select__value {
      &-container {
        &--has-value {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;

          & + .react-select__indicators {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }
      }
    }
  }

  &__option {
    &--is-selected {
      &:after {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url('../../../assets/icons/ok.svg');
      }
    }
  }

  &__value {
    &-container {
      &--has-value {
        background-color: $prim-lighter;

        & + .react-select__indicators {
          background-color: $prim-lighter;
        }
      }
    }
  }

  &__input {
    &-container {
      width: 0.1px;
      overflow: hidden;
    }
  }
}
