.styled-textarea {
  width: 100%;
  border: 1px solid #d8d8dd;
  border-radius: 6px;
  padding: 10px;

  &:hover {
    border: 1px solid #0278ff;
  }

  &:focus {
    box-shadow: -3px -3px 0px #c6ddff, -3px 3px 0px #c6ddff, 3px 3px 0px #c6ddff,
      3px -3px 0px #c6ddff;
    border: 1px solid #0278ff;
  }

  &:focus-visible {
    outline: none;
  }
}
