.feedback-form {
  &__input {
    padding: 0px 0px 0px 10px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #cad3e0;
    border-radius: 6px;
    height: 44px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2c3038;

    &.disabled,
    &[disabled] {
      background-color: #d8d8dd;
      color: #999;
    }
  }

  &__textarea {
    padding: 10px 10px 28px;
    background: #ffffff;
    border: 1px solid #cad3e0;
    border-radius: 6px;
    height: 180px;
    width: 100%;
    font-family: 'Golos';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2c3038;
  }
}
