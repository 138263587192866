$selected-color: #ffffff;

.react-tabs {
  &__tab {
    color: $color-title;
    padding: 8px 9px;
    border-radius: 6px;
    border: 0;
    font-weight: 400;
    font-size: 14px;
    position: static;
    transition: $transition-base;

    &:focus {
      &:after {
        content: none;
      }
    }

    &:not(:last-child) {
      margin-right: 4px;
    }

    &--selected {
      background-color: $selected-color;
      box-shadow: 0px 1px 1px #d8d8dd;
    }

    &-list {
      margin: 0;
      padding: 4px 5px;
      display: inline-block;
      background: #f2f4f7;
      border-radius: 8px;
      border-bottom: 0;
    }
  }
}
