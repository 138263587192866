.news-item-reg {
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e5e5eb;

  &:last-child {
    border-bottom: none;
  }

  &__text {
    margin-bottom: 8px;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__time.MuiTypography-root {
    display: block;
    margin-bottom: 8px;
    color: $color-minor-text;
  }

  &__region {
    cursor: pointer;
    display: inline-block;
    transition: $transition-base;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 2px 8px;
    color: $color-primary-text;

    border: 1px solid #dee8fa;
    border-radius: 3px;

    &:hover {
      color: $color-primary;
      border-color: $color-primary;
    }
  }
}
