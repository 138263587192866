@import '@scss/variables';
@import '@scss/mixins/media-query';

.services-menu {
  margin-top: 10px;
  background-color: #fff;
  width: auto;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(123, 135, 158, 0.12),
    0 8px 32px rgba(123, 135, 158, 0.25);
  border-radius: 8px;
  position: relative;

  &__list {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 24px !important;

    @include media-query('phone') {
      flex-direction: column;
      overflow-y: scroll;
      padding: 0 8px 8px !important;
    }
  }

  &__item {
    min-height: 52px !important;
    padding: 10px !important;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      color: $color-base;
      text-decoration: none;
      font-weight: 400;
      font-size: 15px;

      @include media-query('phone') {
        white-space: pre-line;
        height: auto;
      }
    }
  }

  &__ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 20%;
    margin-right: 16px;

    &.analytics {
      background-color: #8169fb;
    }

    &.library {
      background-color: #ffcc57;
    }

    &.ticket-to-future {
      background-color: #1f968c;
    }

    &.psychological-help {
      background-color: #38bbfc;
    }

    &.additional-edu {
      background-color: #f87545;
    }

    &.assistant {
      background-color: #f86459;
    }

    &.resh {
      background-color: #ffffff;
    }

    &.sphere {
      background-color: #071354;
    }

    &.tests {
      background-color: #48b462;
    }

    &.files {
      background-color: #e3b672;
    }

    &.diary {
      background-color: #43c6cc;
    }
  }

  &-close {
    position: absolute;
    top: 18px;
    right: 14px;
    width: 24px;
    display: block;
    cursor: pointer;

    svg {
      display: block;
    }
  }

  &-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    margin-top: 0;
    height: 12px;
    z-index: 2;
    &:before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 24px 24px;
      border-color: transparent transparent #fff;
    }
  }

  &__popper {
    z-index: 11;
  }

  &__servicesTitle {
    padding: 12px 32px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #818999;

    border-bottom: 1px solid #f2f5fa;

    @include media-query('phone') {
      padding-left: 16px;
    }
  }

  @include media-query('tablet') {
    width: 720px;
  }

  @include media-query('phone') {
    display: flex;
    flex-direction: column;
    width: 240px;
    max-height: 368px;
  }
}
