@mixin font($font_name, $file_name, $weight, $style) {
  @font-face {
    font-family: $font_name;
    font-display: swap;
    src: url('../assets/fonts/#{$file_name}.woff') format('woff'),
      url('../assets/fonts/#{$file_name}.woff2') format('woff2');
    font-weight: #{$weight};
    font-style: #{$style};
  }
}

@include font('Golos', 'GolosText_Bold', '700', 'normal');
@include font('Golos', 'GolosText_Medium', '500', 'normal');
@include font('Golos', 'GolosText_Regular', '400', 'normal');

html {
  scroll-behavior: smooth;
}

html,
body {
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  height: 100%;
}

body {
  color: $color-base;
  font-size: 15px;
  line-height: 22px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
