@import '@scss/variables';

.diary-widget {
  &__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  &__label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  a {
    color: $color-primary;
  }
}

.diary-item {
  &__grade-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  &__grade {
    text-align: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #38b048;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    margin: 0 4px 4px;
    &--bad {
      background-color: #fa7d64;
    }
  }
}
