.table {
  &__settings {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      cursor: pointer;

      &--active {
        background-color: #f7f7f7;
      }
    }
    &-header {
      color: #626b7a;
      font-size: 14px;
      line-height: 1;
    }

    &-actions {
      color: #075ff7;
      font-size: 16px;
      line-height: 1.5;
      cursor: pointer;
    }
  }
}
