.wysiwyg__toolbar,
.ql-toolbar.ql-snow {
  .ql-header {
    min-width: 105px;
  }

  .ql-formats {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
