@import '@scss/variables';

.collection-item {
  margin-bottom: 48px;
  display: block;
  text-decoration: none;
  &__img {
    background-color: $color-bg-grey;
    border-radius: 8px;
    margin-bottom: 16px;
    height: 280px;
    background-size: cover;
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $color-text;
  }
  &__count {
    font-size: 16px;
    line-height: 24px;
    color: $color-text;
  }
}
