.drop-down-brand {
  position: relative;

  &__button {
    cursor: pointer;

    &.disabled,
    &:disabled {
      cursor: default;
      pointer-events: none;
    }
  }

  &__body {
    position: absolute;
    transform: translateX(-10000px); // invisible fix, scroll right
    opacity: 0;
    visibility: hidden;
  }

  &.active {
    z-index: 11; // for plan adaptive nav}

    .drop-down-brand__body {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
}
