// Endpoints
$md-width: 768px;
$lg-width: 1024px;
$xl-width: 1280px;

@mixin media-query($media-query) {
  @if $media-query == 'phone' {
    @media only screen and (max-width: #{$md-width - 1px}) {
      @content;
    }
  } @else if $media-query == 'tablet' {
    @media only screen and (max-width: #{$lg-width - 1px}) {
      @content;
    }
  } @else if $media-query == 'tablet-only' {
    @media only screen and (min-width: $md-width) and (max-width: #{$lg-width - 1px}) {
      @content;
    }
  } @else if $media-query == 'desktop' {
    @media only screen and (min-width: $lg-width) and (max-width: #{$xl-width - 1px}) {
      // @media only screen and (min-width: $large-width) {
      @content;
    }
  } @else if $media-query == 'wide' {
    @media only screen and (min-width: $xl-width) {
      @content;
    }
  }
}
