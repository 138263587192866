@import '@scss/variables';

.sidebar {
  border-right: 1px solid #e5e5eb;
  min-height: 100vh;
  min-width: 260px;
  position: fixed;
  width: 260px;
  display: flex;
  flex-direction: column;
}
.manager-logo {
  position: relative;
  &__text {
    color: #626b7a;
    font-size: 14px;
    line-height: 1;
    margin-top: -4px;
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.manager-navigation {
  &__link {
    color: $color-base;
    font-size: 16px;
    font-weight: 500;
  }
}
