.swiper-slider-news-list {
  position: relative;
  padding-top: 200px;

  &__item-wrapper {
    .news-item {
      width: 296px;
      padding: 0;

      &:hover {
        background-color: transparent;
        box-shadow: none;
        border-radius: 10px;

        .news-item__text {
          color: $color-primary;
        }
      }
    }
  }

  &__arrow {
    transition: $transition-base;
    cursor: pointer;
    position: absolute;
    width: 44px;
    height: 44px;
    background-color: #f2f4f7;
    border-radius: 6px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    background-position: center;
    background-repeat: no-repeat;

    &--left {
      right: 80px;
      top: 150px;
    }

    &--right {
      right: 12px;
      top: 150px;
    }

    &--disable {
      opacity: 0;
    }
  }

  &__title {
    position: absolute;
    left: 0px;
    top: 128px;
  }
}
