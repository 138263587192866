.quiz-detail {
  &__title {
    margin-bottom: 24px;
  }
  &__text {
    margin-bottom: 20px;
  }
  &__info {
    margin-bottom: 36px;
  }
  &__completed {
    background-color: #38b048;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 8px 4px 28px;
    background-position: left 8px center;
    background-repeat: no-repeat;
    border-radius: 4px;
  }
  &__remain {
    color: #626b7a;
    font-size: 14px;
  }
  &__question {
    background-color: #f2f7ff;
    padding: 48px;
    margin-bottom: 32px;
    &-number {
      color: #626b7a;
      margin-bottom: 8px;
    }
    &-title {
      font-weight: 500;
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__option {
    display: flex;
    align-items: center;

    label {
      margin-right: 0;
    }
  }
}
