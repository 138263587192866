.range-picker {
  width: 100%;
  min-width: 232px;
  position: relative;

  &__input {
    height: 44px;
    width: 100%;
    border: 1px solid #cad3e0;
    border-radius: 6px;
    font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #0c1524;
    padding: 6px 10px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
}
