@import '@scss/variables';

.search-widget {
  position: absolute;
  right: -100px;
  background-color: #fff;
  width: 310px;
  z-index: 100;
  top: -23px;

  &__input {
    height: 44px;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 0 6px 6px 0;
    // border: 1px solid #d8d8dd;
    border: 2px solid #005fcc;
    border-left: 0;
    width: 100%;

    &:focus {
      border: 2px solid #005fcc;
      border-left: 0;
      outline: none;
      // box-shadow: -3px -3px 0px #c6ddff, -3px 3px 0px #c6ddff,
      //   3px 3px 0px #c6ddff, 3px -3px 0px #c6ddff;
    }
  }

  &__source-select {
    .react-select {
      &__value {
        &-container {
          &--has-value {
            height: 36px;
            background-color: #f2f4f7;
            margin-left: 3px;

            & + .react-select__indicators {
              margin-top: 3px;
              background-color: #f2f4f7;
              height: 36px;
            }
          }
        }
      }

      &__control {
        border: 2px solid #005fcc;
        border-radius: 6px 0 0 6px;
        border-right: none;
        box-shadow: none !important;

        &:hover {
          border-color: #005fcc;
        }
      }
    }
  }
}
