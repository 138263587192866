@import '@scss/variables';
@import '@scss/mixins/media-query';

.profile {
  padding-top: 48px;

  @include media-query('tablet') {
    padding-top: 6px;
  }

  &__title {
    font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 24px;
  }
  &__info {
    margin-bottom: 10px;
  }
  &__label {
    color: #75747b;
  }
  &__text {
    font-size: 14px;
    color: #2c3038;
    max-width: 626px;
    margin-top: 16px;
  }
  &__section {
    margin-bottom: 48px;
    &--main {
      margin-bottom: 64px;
    }
    &-ico {
      cursor: pointer;
    }
  }
  &__value {
    display: flex;
    align-items: center;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-top: 16px;
    border: 1px solid #cad3e0;
    border-radius: 8px;

    &-item {
      box-shadow: 0 1px 0 #e8e8ed;
      padding: 24px 24px 24px 40px;

      &:first-child {
        border-radius: 8px 8px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 8px 8px;
      }

      &:only-child {
        border-radius: 8px;
      }
    }
  }

  &__notification {
    padding: 4px 8px;
    background-color: #fcefd6;
    color: #836217;
    border-radius: 4px;
    font-size: 14px;
    width: fit-content;
  }
}

.profile-menu__item {
  color: #2c3038 !important;
  padding: 8px 16px !important;
}

#profile-menu-list .profile-menu-list {
  $root: &;
  position: relative;
  height: auto;

  @include media-query('tablet') {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #cad3e0;
    width: calc(100% + 48px);
    margin-left: -24px;
    padding-left: 24px;

    &--opened {
      height: auto;
      padding-bottom: 16px;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__arrow {
    display: none;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    height: 46px;
    padding-right: 61px;
    cursor: pointer;

    @include media-query('tablet') {
      display: flex;
    }
  }

  &__item {
    padding: 8px 16px;

    @include media-query('tablet') {
      padding: 8px 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    a {
      text-decoration: none;
      color: #007aff;
    }

    &--active {
      color: #2c3038;
    }

    & span {
      @include media-query('desktop') {
        font-weight: 500;
      }
    }
  }
}
