@import '../../scss/mixins/media-query';

#faq-list-page {
  .faq {
    &-item {
      @include media-query('phone') {
        padding: {
          top: 16px;
          bottom: 16px;
        }
      }

      &--expanded {
        display: flex;
        align-items: center;

        & .faq__title {
          font-weight: 500;
        }

        @include media-query('phone') {
          min-height: 58px;
        }
      }

      &__open-tab {
        margin-top: 24px;

        @include media-query('phone') {
          margin-top: 16px;
        }

        &-link {
          @include media-query('phone') {
            width: 100%;
          }
        }
      }
    }

    &__title {
      @include media-query('phone') {
        font-size: 16px;
        line-height: 1.6;
      }
    }

    &__response {
      > p {
        margin: 0;
      }

      @include media-query('phone') {
        font-size: 16px;
        line-height: 1.6;
      }
    }
  }

  .show-more {
    margin-top: 64px;

    @include media-query('phone') {
      margin-top: 48px;
    }
  }
}
