@import '@scss/variables';
@import '@scss/mixins/media-query';

.filter-wrapper {
  @include media-query('phone') {
    min-width: 100% !important;
  }
}

.notifications {
  &__filters {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    padding-right: 16px !important;

    @include media-query('phone') {
      align-items: start;
      padding-right: 0 !important;
    }

    .date-picker__wrapper {
      div:first-child {
        width: 100% !important;
      }
    }
  }

  &__filters-subscribe {
    transition: $transition-base;
    cursor: pointer;
    width: 36px;
    height: 36px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:hover {
      background: $color-bg-light;
      border-radius: 4px;
    }
  }

  &__checked-items-actions {
    @include media-query('phone') {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 8px;
    }
  }

  &__search-field {
    background-color: white;
    width: 100%;
  }

  &__table {
    margin-top: 16px;

    &-row {
      $root: &;
      cursor: pointer;

      td {
        height: 56px;
      }

      &-date {
        min-width: 176px;
      }

      &-hide-on-hover {
        display: block;
      }

      &-show-on-hover {
        display: none;
      }

      &:hover {
        background: $color-bg-grey;

        #{$root}-hide-on-hover {
          display: none;
        }

        #{$root}-show-on-hover {
          display: block;
        }
      }
    }
  }

  &__table-date {
    width: 128px !important;
    padding-right: 16px !important;
    padding-left: 24px !important;
  }

  &__table-system {
    width: 200px !important;
    padding-right: 16px !important;
  }

  &__table-checkbox {
    box-sizing: content-box;
    width: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  &__table-name {
    padding: 8px 16px !important;
    width: 800px !important;

    &-trash-btn {
      margin-left: 16px !important;
      background-color: #fff !important;

      @include media-query('phone') {
        margin-left: 0 !important;
      }
    }

    &-cancel-btn {
      margin-left: 16px !important;

      @include media-query('phone') {
        margin-left: 0 !important;
      }
    }
  }

  &__dropdown {
    position: relative;
    width: 208px;
    background: #fff;
    padding: 8px 0;
    box-shadow: 0px 1px 8px rgba(123, 135, 158, 0.12),
      0px 8px 32px rgba(123, 135, 158, 0.25);
    border-radius: 8px;

    &-title {
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;
      color: $color-minor-text;
      padding: 16px;
    }

    &-item {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
      padding: 8px 12px;
      color: #2c3038;
      background-color: transparent;
      border-radius: 6px;
      border: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;

      &:hover {
        background-color: #f2f4f7;
      }

      &--primary {
        color: $color-primary;
      }
    }

    &-divider {
      width: 100%;
      height: 1px;
      background: #dce2eb;
      margin: 8px 0;
    }
  }

  &__dropdown-checkbox {
    display: flex;

    &-icon {
      margin-left: 4px;

      svg {
        width: 12px;
      }
    }
  }

  &__footer {
    position: relative;
    padding-top: 64px;
    display: flex;

    @include media-query('phone') {
      flex-direction: column-reverse;
      align-items: start;
      row-gap: 24px;
    }
  }

  &__page-size {
    position: absolute;
    top: 64px;
    left: 0;
    bottom: 0;
    flex: none;
    display: flex;
    align-items: center;

    &-desc {
      margin-left: 16px;
      font-size: 14px;
      color: #818999;
    }

    @include media-query('phone') {
      position: static;
    }
  }

  &__pagination {
    flex: auto;
    display: flex;
    justify-content: center;
    padding: 0 160px;

    @include media-query('phone') {
      padding: 0;
    }
  }
}

#notifications-table .notifications__table {
  &-cell {
    &--header {
      background-color: #f2f4f7;
      color: #818999;
      border-bottom: none;
      font-weight: 400;
      font-size: 16px;

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  &-cell.pr-10 {
    padding-right: 10px !important;
  }

  &-cell.pl-0 {
    padding-right: 0 !important;
  }
}
