@import '@scss/variables';

.header {
  box-shadow: 0px 1px 0px rgba(229, 229, 235, 0.5);
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 200px;
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    /*    background-image: linear-gradient(
      180deg,
      #f7f9fc 0%,
      rgba(247, 249, 252, 0) 100%
    );*/
    z-index: -1;
  }
  &__logo {
    z-index: 2;
    color: $color-primary;
    margin: 0 54px 0 0;
    display: block;
    position: relative;
    svg {
      display: block;
    }
  }

  &__navigation {
    margin-right: auto;
  }

  &__login {
    &-link {
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__icon {
    display: block;
  }
}

.nav {
  display: flex;

  &__link,
  &__link.MuiButton-root {
    color: $color-title;
    @extend %font-button;
    padding: 26px 16px;
  }
}

.toolbar {
  display: flex;
  align-items: center;
}

.user {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $color-base;
  text-decoration: none;

  &__name {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    padding: 9px 16px 9px 8px;
  }

  &__logo {
    height: 40px;
    width: 40px;
    border-radius: 8px;
  }

  &:hover {
    color: $color-primary;
  }
}

@media (max-width: 1023px) {
  .header {
    &__logo {
      margin: 20px 24px 20px 0;
    }
  }
  .menu {
    &__trigger {
      position: relative;
      top: -1px;
      cursor: pointer;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      width: 18px;
      height: 18px;
      font-size: 0;
      transition: background 0.3s;
      z-index: 1000;

      &-line {
        display: block;
        position: absolute;
        top: 9px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #000;

        &:before,
        &:after {
          position: absolute;
          display: block;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #000;
          content: '';
          transition-duration: 0.3s, 0.3s;
          transition-delay: 0.3s, 0;
        }

        &:before {
          top: -6px;
          transition-property: top, transform;
        }

        &:after {
          bottom: -6px;
          transition-property: bottom, transform;
        }

        &.active {
          background-color: #007aff;

          &:before,
          &:after {
            background-color: #007aff;
          }
        }
      }

      &--open {
        .menu__trigger-line {
          background: none;
        }

        .menu__trigger-line:before {
          top: 0;
          transform: rotate(45deg);
        }

        .menu__trigger-line:after {
          bottom: 0;
          transform: rotate(-45deg);
          width: 100%;
        }

        .menu__trigger-line:before,
        .menu__trigger-line:after {
          transition-delay: 0, 0.3s;
          background-color: #000;
        }
      }
    }
  }
}
