@import '@scss/variables';

.library-widget {
  position: relative;
  margin-top: 80px;
  &:first-child {
    margin-top: 24px;
  }
  &__item {
    text-decoration: none;
    color: $color-text;
    &-img {
      height: 321px;
      background-size: cover;
      background-position: center;
      border: 1px solid $color-border-grey;
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 24px 16px;
      box-sizing: border-box;
      position: relative;
      &--more {
        background-color: #fff;
        border: 1px solid $color-primary;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-ico {
      position: absolute;
      display: block;
      content: '';
      bottom: 10px;
      left: 16px;
    }
    &-text {
      color: $color-primary;
      text-align: center;
    }
    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
      color: #fff;
    }
    &-lesson {
      background: rgba(12, 21, 36, 0.1);
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      line-height: 20px;
      display: inline-block;
      margin-bottom: 8px;
      padding: 4px 8px;
    }
    &-tag {
      color: $color-primary;
      font-size: 14px;
      line-height: 20px;
      background: #ffffff;
      border: 1px solid $color-border-grey;
      border-radius: 4px;
      display: inline-block;
      padding: 2px 8px;
      white-space: nowrap;
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
  &__slider {
    position: relative;
  }
  &__arrow {
    position: absolute;
    background-color: #f2f4f7;
    border-radius: 6px;
    width: 44px;
    height: 44px;
    z-index: 10;
    cursor: pointer;
    top: 140px;
    background-position: center;
    background-repeat: no-repeat;
    &--next {
      right: -20px;
    }
    &--prev {
      left: -20px;
      transform: rotate(180deg);
    }
    &--disable {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .library-widget__arrow--prev {
    left: -10px;
  }
  .library-widget__arrow--next {
    right: -10px;
  }
}
