.quiz-list {
  &__status {
    &-label {
      padding: 2px 8px;
      background: #ffffff;
      border: 1px solid #cad3e0;
      border-radius: 4px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
