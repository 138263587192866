@import '@scss/variables';

.schedule-widget {
  position: relative;
  margin-bottom: 32px;
  margin-top: 32px;
  background-color: #fff;
  border-radius: 12px;
  &__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  &__label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  a {
    color: $color-primary;
  }
}

.schedule-item {
  &__lesson {
    font-size: 16px;
    margin-bottom: 4px;
  }
  &__group {
    font-size: 14px;
    color: #626b7a;
  }
  &__cabinet {
    font-size: 14px;
    color: #626b7a;
  }
  &__time {
    font-size: 14px;
    color: #626b7a;
  }
  &__comment {
    font-size: 14px;
    color: #006de5;
  }
  &__replace {
    background-color: #fff0ed;
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    line-height: 20px;
    padding: 4px 4px 4px 8px;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    margin-bottom: 4px;
    &-item {
      color: #626b7a;
      text-decoration: line-through;
    }
    &-btn {
      color: #ab3535;
      background: #ffe6e6;
      border-radius: 4px;
      padding: 4px;
    }
  }
}

@media (max-width: 768px) {
  .diary-widget {
    margin-bottom: 40px;
    padding: 24px 32px 30px;
    background-color: #fff;
    border-radius: 12px;
  }
}
