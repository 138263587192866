.chip {
  display: inline-flex;
  align-items: center;
  height: 28px;
  padding: 4px 8px;
  line-height: 20px;
  font-size: 14px;
  border-radius: 4px;

  & + & {
    margin-left: 8px;
  }

  &--primary {
    color: #075ff7;
    background: #f5f9ff;
  }

  svg {
    display: inline-block;
    max-width: 16px;
    max-height: 16px;
    margin-right: 6px;
  }
}
