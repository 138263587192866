@import '@scss/variables';

.filter {
  &__header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
    padding-top: 24px;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    padding-bottom: 16px;
    padding-top: 16px;
    background-color: #fff;
  }

  &-toggle-btn {
    position: relative;

    & svg path {
      transition: $transition-base;
    }

    &--active {
      color: #007aff !important;

      & path {
        stroke: #007aff !important;
      }
    }

    &--active-news {
      color: #007aff !important;
      svg path {
        stroke: #007aff !important;
      }
    }
  }

  &__count {
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: #ffffff;
    background-color: #007aff;
    margin-left: 12px;
    line-height: 1;

    &.reset {
      margin-left: 0;
      margin-right: 4px;
    }

    &-button {
      background: 0;
      border: 0;
      padding: 4px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: inherit;
      color: #626b7a;
      cursor: pointer;
      transition: $transition-base;

      &:hover {
        background-color: #e5e5eb;
      }
    }
  }

  &__close-btn {
    width: 44px;
    height: 44px;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }
}

.stub-element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.7;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;
}

.mandatory-field {
  margin-left: 2px;
  color: $color-mandatory-field;
}
