.subscribe {
  &__head {
    font-size: 16px;
    background: #f2f4f7;
    border-radius: 8px;
    color: $color-minor-text;
  }

  &__row {
    display: flex;
    padding: 15px 24px;
    border-radius: 8px;

    &:hover {
      background: #f2f4f7;
    }

    &-title {
      font-size: 18px;
      flex: auto;
    }

    &-push {
      flex: none;
      display: flex;
      justify-content: center;
      width: 80px;
      margin-right: 56px;
    }

    &-email {
      flex: none;
      display: flex;
      justify-content: center;
      width: 80px;
    }
  }

  &__group {
    margin-top: 40px;

    &-title {
      padding: 15px 24px;
    }
  }
}
