div[role='row'] {
  &:hover {
    > .MuiPickersDay-root {
      background-color: #1976d2;
      color: #fff;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      &:last-child {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }

  .Mui-selected {
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
  }

  > .MuiPickersDay-root {
    border-color: transparent !important;
  }
}
