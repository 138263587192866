@import '@scss/variables';

.notification-detail {
  padding-top: 48px;

  &__push-top {
    margin-top: 16px;
  }

  &__link {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    font-style: normal;

    &-icon {
      text-align: center;
      vertical-align: middle;
      width: 20px;
      height: 20px;
    }
  }

  &__card {
    max-width: 952px;
    margin-top: 24px;
    padding: 48px 96px 96px 48px;
    border: 1px solid #dce2eb;
    border-radius: 8px;

    &-footer {
      margin-top: 32px;
      display: flex;
    }
  }

  &__chip {
    margin-right: 24px;
    padding: 4px 8px;
    background: #f2f4f7;
    border-radius: 4px;
    color: #626b7a;
    font-size: 14px;
    line-height: 20px;

    &--transparent {
      background: none;
    }
  }
}

.notification-detail-loader {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $color-primary !important;
  }
}
