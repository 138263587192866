@import '@scss/mixins/media-query';

.form-field {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  grid-column-gap: 24px;
  min-height: 0;
  min-width: 0;
  align-items: center;

  @include media-query('phone') {
    grid-template-columns: minmax(0, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 8px;
    align-items: start;
  }
  &__label {
    color: #2c3038;

    &-required {
      color: #fa3e19;
    }
  }
}

.feedback-tooltip {
  &:hover {
    svg path {
      stroke: #007aff;
    }
  }
}
