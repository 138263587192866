.quiz-result {
  &__title {
    &-date {
      font-size: 14px;
      color: $color-minor-text;
      margin-right: 8px;
    }

    &-status {
      display: flex;
      align-items: center;
      margin-right: 24px;
      font-size: 14px;
      color: #fff;
      background: #38b048;
      border-radius: 4px;
      padding: 6px 8px;
      line-height: 20px;

      & svg {
        margin-right: 4px;
      }

      &--draft {
        background: $color-minor-text;
      }
    }

    &-close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 44px;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }

  &__answer {
    display: flex;
    font-size: 14px;
    line-height: 20px;

    & + & {
      margin-top: 16px;
    }

    &-label {
      flex: auto;

      &--active {
        color: $color-primary;
      }
    }

    &-percentage {
      flex: none;
      width: 40px;
      margin-left: 16px;
    }

    &-count {
      flex: none;
      width: 40px;
      margin-left: 16px;
      color: $color-minor-text;
    }
  }

  &__drawer {
    width: 468px;
    padding: 20px 24px;

    &-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
