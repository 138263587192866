@import '@scss/variables';

.news-list {
  &__title {
    display: flex;
    align-items: center;

    &-object {
      border-radius: 4px;
      height: 40px;
      width: 48px;
      background-color: $color-bg-grey;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__status {
    &-label {
      padding: 2px 8px;
      background: #ffffff;
      border: 1px solid #cad3e0;
      border-radius: 4px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
