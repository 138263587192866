.wysiwyg {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__editor {
    .ql-editor {
      border: 1px solid #ccc;
      border-top: none;
      resize: vertical;
      height: 500px;
    }

    .ql-container {
      font-family: Helvetica, Arial, sans-serif;
      font-size: 14px;
      border: none;
    }

    .ql-tooltip[data-mode='link'],
    .ql-tooltip[data-mode='video'] {
      &::before {
        content: 'Введите ссылку:';
      }
    }

    .ql-tooltip {
      &::before {
        content: 'Перейти:';
      }

      &.ql-editing a.ql-action::after {
        content: 'Сохранить';
      }

      a.ql-action::after {
        content: 'Изменить';
      }

      a.ql-remove::before {
        content: 'Удалить';
      }
    }
  }
}
