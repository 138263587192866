.links__section-item {
  border-bottom: 1px solid #e8e8ed;
  &-category {
    padding: 24px 0;
    cursor: pointer;
    font-size: 18px;
  }
}
.links__item {
  &-link {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #e8e8ed;
    &:last-child {
      border-bottom: none;
    }
    &-row {
      align-items: center;
    }
    &-title {
      padding: 20px 0;
    }
    &-img-wrap {
      text-align: right;
    }
    &-img {
      max-height: 40px;
      max-width: 140px;
    }
  }
}

.search-item {
  margin-top: 12px;
  padding-top: 12px;
  cursor: pointer;

  & + & {
    border-top: 1px solid #ddd;
  }

  &__body {
    color: #818999;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
