//colors
$color-title: #151b24;
$color-text: #262626;
$color-base: #171725;

$color-primary: #007aff;
$color-primary-text: #006de5;
$color-minor-text: #626b7a;
$color-stroke-first: #4d94ff;
$color-text-selected: #bbdefd;
$color-border-grey: #dce2eb;
$color-bg-grey: #f7f7f7;
$color-bg-light: #f5f7fa;

$color-mandatory-field: #ff0000;

$transition-base: all 0.2s ease;

//width
$news-detail-page-width: 890px;
//desktop

%font-h1 {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}

%font-h2 {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 42px;
}

%font-h3 {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}

%font-h4 {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

%font-h5 {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

%font-h6 {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

%font-body {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}

%font-additionally {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

%font-caption {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

%font-title-caption {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

%font-button {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

%font-p_small {
  text-transform: none;
  font-family: 'Golos', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

%reset-pos {
  padding: 0;
  margin: 0;
  width: auto;
  min-width: auto;
  max-width: none;
}
