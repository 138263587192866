@import '@scss/variables';

.smart {
  min-height: 100vh;
  background-color: #202124;

  &__item {
    &-cover {
      height: 258px;
      overflow: hidden;
      background-color: #36373a;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      & img,
      &-color {
        width: 100%;
        height: inherit;
        object-fit: cover;
        object-position: 50%, 50%;
      }

      &-color {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__slider {
    margin-top: 24px;
    padding: 2px;

    &-slide {
      padding: 6px 6px 10px;

      &:focus-within {
        outline: 2px solid #feb52b;
        border-radius: 8px;
      }
    }
  }
  &__hide-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__portfolio {
    width: 1290px;
    display: flex;
    flex-wrap: wrap;

    &-item {
      box-sizing: border-box;
      flex: none;
      width: 180px;
      margin-right: 32px;
      margin-top: 32px;

      a {
        padding: 4px;
        border-radius: 8px;
        border: 2px solid transparent;

        &:focus {
          border: 2px solid #feb52b;
        }
      }
    }
  }
}
