.notifications-counter {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.6666666em;
  height: 1.6666666em;
  font-size: 12px;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  color: #ffffff;
  background-color: #d82553;
  border: 1px solid #ffffff;
  border-radius: 50%;
}
