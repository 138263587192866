.quiz-form {
  &__title {
    font-size: 24px;
    font-weight: 500;
    padding: 24px 0;
  }

  &__row {
    $root: &;
    display: flex;
    margin-bottom: 16px;
    max-width: 768px;

    &-left {
      flex: none;
      width: 200px;
      padding-right: 24px;
      color: #626b7a;
      font-size: 16px;
    }

    &-right {
      flex: auto;
    }

    &--push-top {
      margin-top: 32px;
    }

    &--controls {
      justify-content: space-between;
      padding: 24px 32px;
      background: #f2f4f7;
      border-radius: 6px;

      #{$root}-left {
        width: auto;
      }

      #{$root}-right {
        flex: none;
      }
    }
    &--border-top {
      padding-top: 40px;
      border-top: 1px solid #dce2eb;
    }
  }

  &__textarea {
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
  }

  &__text-btn {
    font-family: inherit;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
    color: #006de5;
    cursor: pointer;
  }

  &__parallel-container {
    max-width: 120px;
  }

  &__input-checkbox {
    position: relative;

    input {
      padding-right: 40px !important;
    }

    &-check {
      position: absolute !important;
      top: 11px;
      right: 12px;
    }

    & + & {
      margin-top: 4px;
    }
  }

  &__answer {
    display: flex;
    align-items: center;

    &-input {
      flex: auto;
      display: flex;
      align-items: center;
    }

    &-btn {
      flex: none;
      display: flex;
      align-items: center;
      width: 32px;
      margin-left: 16px;
    }
  }
}
