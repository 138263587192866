@import '@scss/variables';

.quiz-item {
  margin-bottom: 24px;
  border: 1px solid #cad3e0;
  border-radius: 16px;
  padding: 56px 64px;
  transition: all 0.3s;
  &-title {
    margin-bottom: 24px;
    transition: all 0.3s;
  }
  &-remain {
    display: flex;
    margin-top: 16px;
    color: $color-minor-text;
  }
  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 8px 24px rgba(202, 211, 224, 0.25), 0 1px 2px #dce2eb;
    .quiz-item-title {
      color: $color-primary;
    }
  }
}
