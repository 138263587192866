@import '@scss/variables';

.smart {
  min-height: 100vh;
  background-color: #0c1524;

  &__widget {
    &-title {
      color: #f5f7fa;
      a {
        color: inherit;
        text-decoration: none;
        border-radius: 4px;

        &:focus {
          outline: 4px solid #ffcc00;
        }
      }
    }
  }

  .library-widget__item-tag {
    background-color: #13223a;
    color: #ffffff;
    border-color: transparent;
  }

  .library-widget__item-img--more {
    background-color: #13223a;
    border-color: transparent;

    .library-widget__item-text {
      color: #ffffff;
    }
  }

  .video-widget__item-tag {
    background-color: #13223a;
    color: #ffffff;
    border-color: transparent;
  }

  .video-widget__item-img--more {
    background-color: #13223a;
    border-color: transparent;

    .video-widget__item-text {
      color: #ffffff;
    }
  }

  .library-widget__item-img {
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    border-color: transparent;
  }

  .library-widget__item:focus {
    outline: none;

    .library-widget__item-img {
      outline: 4px solid #ffcc00;
    }
  }

  .video-widget__item-img {
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    border-color: transparent;
  }

  .video-widget__item:focus {
    outline: none;

    .video-widget__item-img {
      outline: 4px solid #ffcc00;
    }
  }
}
