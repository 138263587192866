@import '@scss/variables';

.file-upload {
  background: #ffffff;
  border: 2px dashed #cad3e0;
  border-radius: 8px;
  height: 384px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: 16px;
    line-height: 1.5;
    color: #2c3038;
  }

  &__btn {
    display: flex;
    align-items: center;
    padding: 15px 32px 15px 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5;
    background: #ffffff;
    border: 1px solid #cad3e0;
    border-radius: 8px;

    > svg {
      margin-right: 12px;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 1.5;
    color: $color-minor-text;
  }

  &__btn-group {
    margin-top: -60px;
    margin-bottom: 60px;
    margin-left: 16px;
  }

  &__trash-btn {
    margin-left: 16px !important;
    background-color: #fff !important;
    border-color: #fa3e19 !important;
    color: #fa3e19 !important;
  }

  &__trash-icon path {
    stroke: #fa3e19;
  }
}

.files-dropzone {
  cursor: pointer;

  &-active {
    .file-upload {
      background-color: #f2f4f7;
    }
  }

  &--hidden {
    display: none;
  }
}
