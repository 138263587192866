@import '../../../scss/mixins/media-query.scss';

#common-filter .filter-block {
  border: 1px solid #d8d8dd;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 4px;

  &__column {
    padding: 0 4px;
  }
  &__input {
    width: 100%;
    border: none;
    padding: 8px 10px;
    font-size: 16px;
    line-height: 1.3rem;
  }
  &__toggle-btn {
    &--active {
      color: #007aff !important;
      & path {
        stroke: #007aff !important;
      }
    }
  }
  &__wrapper {
    margin-bottom: 36px;
    position: relative;
  }
  &__info {
    color: #75747b;
    font-size: 14px;
    position: absolute;
    bottom: -30px;
    font-weight: 300;
  }
  &__filter-reset {
    margin-left: 20px;
  }
  &__filter-count {
    background: #fa5332;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
  }
  &__addition {
    margin-top: 16px;
  }
  &__filter-reset {
    cursor: pointer;
  }
  &__filter-count {
    display: inline-block;
  }
  &__select {
    & > fieldset {
      border: none !important;
    }
    &--category {
      max-width: 300px;
    }
    & > div {
      background-color: #f3f3f4;
    }
    &--active {
      & > div {
        color: #fff;
        background-color: #007aff;
      }
      & path {
        color: #fff;
      }
    }
  }

  &__show-btn {
    padding: 8px 24px 8px 24px;
    font-size: 14px;
    line-height: 1.3rem;

    &:hover {
      background: none;
    }

    @include media-query('phone') {
      padding: 0;
      min-width: 0;
      background: none;
    }

    &-text {
      margin-left: 5px;

      @include media-query('phone') {
        display: none;
      }
    }
  }

  &__search {
    flex-grow: 1;

    > .MuiOutlinedInput-root {
      padding-right: 4px;
    }
  }
}
