.snack-content {
  display: flex;
  align-items: center;

  &__icon {
    display: inherit;
    margin-right: 16px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
