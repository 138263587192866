.footer-faq-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: $color-text;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__card {
    box-shadow: none;
    background-color: #dce2eb;
  }

  &__card-header {
    padding-left: 10px;
    cursor: pointer;
  }

  &__card-header-content {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    justify-content: center;
  }

  &__icon-arrow-up {
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
}
