.quiz-task {
  $root: &;
  position: relative;
  min-width: 800px;
  padding: 40px calc(100% - 744px) 16px 24px;
  border-top: 1px solid #dce2eb;

  &__controls {
    display: none !important;
    position: absolute;
    top: 8px;
    right: 8px;

    button {
      padding: 8px;
      margin-right: 1px;
      background-color: #fff;

      svg {
        display: block;
        height: 24px;
      }
    }
  }

  &:hover {
    background-color: $color-bg-grey !important;

    #{$root}__controls {
      display: block !important;
    }
  }
}
