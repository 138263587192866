@import '@scss/variables';

.notification-menu {
  margin-top: 10px;
  background-color: #fff;
  width: 400px;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(123, 135, 158, 0.12),
    0 8px 32px rgba(123, 135, 158, 0.25);
  border-radius: 8px;
  position: relative;

  &__items-wrapper {
    max-height: calc(100vh - 150px);
    overflow: auto;

    /* полоса прокрутки (скроллбар) */
    &::-webkit-scrollbar {
      width: 8px; /* ширина для вертикального скролла */
      background-color: #f8f8f8;
      border-radius: 6px;
    }

    /* ползунок скроллбара */
    &::-webkit-scrollbar-thumb {
      background-color: $color-minor-text;
      width: 8px;
      border-radius: 4px;
    }
  }

  &__item {
    //min-height: 60px !important;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: $color-base;
      text-decoration: none;
    }
  }

  &__notification {
    white-space: normal;
    overflow: hidden;
    max-height: 50px;
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__ico {
    display: inline-block;
    width: 60px;
  }

  &__control {
    display: flex;
  }

  &-close {
    display: block;
    cursor: pointer;
    margin-left: 20px;

    svg {
      display: block;
    }
  }

  &-arrow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    &:before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 24px 24px;
      border-color: transparent transparent #fff;
    }
  }

  &__popper {
    z-index: 11;
  }
}
