@import '@scss/variables';

.gosuslugi {
  margin-bottom: 80px;
  &__item {
    border-radius: 48px;
    display: inline-block;
    padding: 16px 24px;
    margin-bottom: 16px;
    margin-right: 16px;
    text-decoration: none;
    font-size: 18px;
    line-height: 24px;
    border: 1px solid $color-primary;
    color: $color-primary;
    transition: all 0.35s;
    &:hover {
      color: #fff;
      background-color: #075ff7;
    }
  }
}

@media (max-width: 768px) {
  .gosuslugi {
    padding: 20px;
    margin-bottom: 40px;
  }
}
