@import '../../scss/mixins/media-query.scss';

.footer {
  margin-top: 160px;

  @include media-query('phone') {
    margin-top: 80px;
  }

  &__menu {
    &-item {
      color: #2c3038;
      text-decoration: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      @include media-query('phone') {
        display: block;
      }

      & + & {
        margin-left: 40px;

        @include media-query('phone') {
          margin-left: 0;
          margin-top: 16px;
        }
      }
    }
  }

  &__phone {
    color: #2c3038;
    font-size: 18px;
    line-height: 28px;
    display: block;
    text-decoration: none;
  }
  &__email {
    color: #818999;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
  }
}
